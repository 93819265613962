<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card id="CounsellingApp">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa fa-solid fa-hourglass" aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
              <span class="btn_in_header">
                <b-button class="mr-2 mb-2" variant="primary" @click="showCreateSession" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'">{{cvbtnCreateSession}}</b-button>
                <b-button variant="primary mb-2" @click="goToCalendar" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" >{{cvbtnBlockCalendar}}</b-button>
              </span>
            </h4>
            <span>{{ userData.user_name }}</span>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="3" md="3" lg="3" xl="3" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>

              <!-- Counsellor Name Filter -->
              <b-col sm="3" md="3" lg="3" xl="3" class="mb-2" v-if="userData.user_role == 'USERROLE11111'">
                <select v-model="whereFilter.counsellor_id" class="form-control" @change="setCounsellor" v-if="counsellorObjList.length > 0" title="Counselor List">
                  <option :value="null" disabled>Choose Counselor</option>
                  <option v-for="(counsellor, index) of counsellorObjList" :key="(index+1)" :value="counsellor.user_id">
                    {{counsellor.user_name}}
                  </option>
                </select>
              </b-col><!-- Counsellor Name Filter -->

              <!-- Counselling Status -->
              <b-col sm="2" md="3" lg="3" xl="3" class="mb-2">
                <select v-model="whereFilter.spcm_state" class="form-control" @change="spcmList()">
                  <option :value="null" disabled>Choose Status</option>
                  <option v-for="(state, index) of SPCM_STATE_LIST" :key="(index+1)" :value="index">
                    {{state}}
                  </option>
                </select>
              </b-col><!-- Counselling Status -->

              <b-col sm="3" md="3" lg="3" xl="3" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getspcmDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row :key="updateInfo">
              <b-col class="container_minheight" md="12" v-if="spcmObjList && spcmObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="spcmObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(id)="data">
                    <span style="display: block;width: 50px" title="View Session Details">{{spcmObjList.indexOf(data.item)+1}}</span>
                  </template>

                  <template v-slot:cell(user_name)="data">
                    <span class="pointer" @click="viewDetails(`/user/${data.item.user_id}`)"> {{ data.item.user_name }}</span><br>
                    <span><small>
                      Id:&nbsp;{{ data.item.user_id }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br>
                      Email:&nbsp;{{ data.item.user_email }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_email)"></i><br>
                      Mobile:&nbsp;{{data.item.user_mobile}}<br><br>
                      Class:&nbsp;{{ data.item.class_name }}<br>
                      School:&nbsp;{{ data.item.org_name }}<br>
                      {{data.item.org_city ? data.item.org_city : '' }}
                      {{data.item.org_state ? ', ' + data.item.org_state : '' }}
                      {{data.item.org_country ? ', ' + data.item.org_country : '' }}<br>
                    </small></span>
                    <div class="button_align">
                      <template v-if="adminView">
                        <b-button variant="actionIcon mr-1 mb-2" size="sm" @click="spcmOpenEditModal('MODAL', data.item)"><i class="ri-exchange-line m-0 font-size-20 primary-color" title="Change Counselor"></i></b-button>
                        <b-button variant="actionIcon mr-1 mb-2" @click="showSpcmDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line font-size-20 primary-color m-0"></i></b-button>
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="openReport(data.item.report)" v-if="data.item.report" size="sm" title="View GPaths Report"><i class="font-size-20 fa fa-map-signs primary-color" aria-hidden="true"></i></b-button>
                        <b-button @click="viewSessionDetails(data.item.spcm_id)" class="actionIcon btn" style="cursor: pointer;display: block;width: 50px" title="View Session Details"><i class="fa fa-solid font-size-20 ri-user-star-fill primary-color" aria-hidden="true"></i></b-button>
                      </template>
                      <template v-else-if="counsellorView">
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="openReport(data.item.report)" v-if="data.item.report" size="sm" title="View GPaths Report"><i class="font-size-20 fa fa-map-signs primary-color" aria-hidden="true"></i></b-button>
                        <b-button @click="viewSessionDetails(data.item.spcm_id)" class="actionIcon btn" style="cursor: pointer;display: block;width: 50px" title="View Session Details"><i class="fa fa-solid font-size-20 ri-user-star-fill primary-color" aria-hidden="true"></i></b-button>
                      </template>
                    </div>
                  </template>

                  <template v-slot:cell(spcm_state)="data">
                    <b-badge title="Category" class="text-wrap" :style="getCounsellingStateColorCode(98)">{{ data.item.spcm_category }}</b-badge><br><br>
                    <b-badge title="Click to Update Status" @click="showSpcmStatusUpdateOpenDialog(data.item)" class="text-wrap pointer" :style="getCounsellingStateColorCode(data.item.spcm_state)">{{ SPCM_STATE_LIST[data.item.spcm_state] }}</b-badge>
                  </template>

                  <template v-slot:cell(spcm_id)="data">
                    <span class="primary-color">Counselor:&nbsp;<span>{{ data.item.counsellor_email }}</span></span><br>
                    <span v-if="data.item.latest_counselor_notes && data.item.latest_counselor_notes.length > 0" @click='viewSessionDetails(data.item.spcm_id)' v-html="getNotes(data.item)"></span>
                    <span v-else><br><span @click='viewSessionDetails(data.item.spcm_id)' class="primary-color pointer">
                      <b-badge>Add Notes</b-badge>
                    </span></span>
                  </template>

                  <template v-slot:cell(modified_on)="data">
                    <span>{{ data.item.modified_on | dateFormatDDMMYYYY }}</span>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Spcm Change Counselor / To be updated for Full-fledged Edit Functionality -->
    <b-modal
      v-model="showModelSpcmEdit"
      scrollable
      :title="cvEditModalHeader"
      size="lg"
    >
      <SpcmEdit :propOpenedInModal="true" :propSpcmObj="spcmEditObj" @emitCloseSpcmEditModal="closeSpcmEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="spcmOpenEditModal('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSpcmEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- Spcm Change Counselor / To be updated for Full-fledged Edit Functionality -->

    <!-- Spcm Create Your Custom Session -->
    <b-modal
      v-model="showSessionCreationModal"
      scrollable
      :title="cvModalTitle"
      size="lg"
    >
      <InviteUser :propProductCode="'spcm'" :propProductName="'Counselor'" :propOpenedInModal="true" :propCounsellor="userData" @emitCloseInviteUser="emitCloseInviteUser"/>
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="spcmOpenEditModal('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCreateSession()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>
    <!-- Spcm Create Your Custom Session  -->

    <!-- Update SPCM Status -->
    <b-modal v-model="showModelSpcmStatusUpdate" size="sm" title="Update Status" ok-title="Update" cancel-title="Cancel">
        <!-- Counselling Status -->
        <b-col sm="12" md="12" lg="12" xl="12" class="mb-2">
          <select v-model="whereFilter.spcm_state" class="form-control">
            <option :value="null" disabled>Choose Status</option>
            <option v-for="(state, index) of SPCM_STATE_LIST" :key="(index+1)" :value="index">
              {{state}}
            </option>
          </select>
        </b-col><!-- Counselling Status -->

      <template #modal-footer="">
        <b-button size="sm" @click="showModelSpcmStatusUpdate = false">{{cvbtnModalCancel}}</b-button>
        <b-button size="sm" variant="primary" @click="spcmStatusUpdate(spcmObj, whereFilter.spcm_state)">Update</b-button>
      </template>
    </b-modal><!-- Update SPCM Status -->

    <b-modal v-model="showModelSpcmDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSpcmDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="spcmDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { Spcms } from "../../../FackApi/api/Spcm.js"
import SpcmEdit from "./SpcmEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import InviteUser from "../../../components/InviteUser.vue"
// import { SpcmNotess } from "../../../FackApi/api/spcmNotes"
import { SpcmSessions } from "../../../FackApi/api/spcmSession.js"
import { User } from "../../../FackApi/api/user"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "SpcmList",
  components: {
    SpcmEdit,
    InviteUser
  },
  data () {
    return {
      apiName: "spcm_list",
      cvCardTitle: "Counselling Appointments",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Change Counsellor",
      cvAddModalHeader: "Add Appointment",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Appointment",
      showModelSpcmAdd: false,
      showModelSpcmEdit: false,
      showModelSpcmDelete: false,
      sortBy: "name",
      sessionList: [],
      sortDesc: false,
      columns: [],
      spcmObjList: null,
      spcmEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      spcmObj: null,
      whereFilter: {
        counsellor_id: "null",
        spcm_state: "null",
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      counsellorObjList: [],
      adminView: false,
      counsellorView: false,
      counsellorId: null,
      cvbtnCreateSession: "Add Appointment",
      cvbtnBlockCalendar: "Calendar",
      cvModalTitle: "Add Appointment",
      showSessionCreationModal: false,
      showModelSpcmStatusUpdate: false,
      SPCM_STATE_LIST: {},
      SPCM_STATE_COLORCODE_LIST: {},
      updateInfo: 0
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.getDateFilter()

    if (this.userData.user_role == "USERROLE11111") {
      this.adminView = true
    }
    else if (this.userData.user_role == "USERROLE11118") {
      this.adminView = false
      this.counsellorView = true
      this.counsellorId = this.userData.user_id
    }

    this.spcmStateList()
    this.spcmList()
    this.setFilters()
    this.setUserView()
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter.dateRange = storeDate.dateRange
      }
    },
    /**
     * get Counselling State Color Code
     */
    getCounsellingStateColorCode (stateId) {
      if (stateId == 99) {
        return `vertical-align: middle; color: #FFF; background: orange !important;`
      }
      else if (stateId == 98) {
        return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
      }
      else {
        return `vertical-align: middle; color: #FFF; background: ${this.SPCM_STATE_COLORCODE_LIST[stateId]} !important;`
      }
    },
    /**
     * setCounsellor
    */
    async setCounsellor () {
      this.spcmList()
    },
    /**
     * emitCloseInviteUser
     * User Invited from Counsellor Dashboard
     */
    async emitCloseInviteUser (inviteAddResp, slot) {
      if (!slot) {
        // Scheduling an appointment time for counselling session is not mandatory. If slot is not available, set the counselling appointment to current timeestamp
        slot = {
          spcm_calendar_id: "SPCM" + Math.floor(100000 + Math.random() * 900000), // Generating a random spcm_calendar_id
          slot_start_ts: moment().unix(), // setting the slot start time to current timestamp
          slot_duration: 3600000
        }
      }
      this.showSessionCreationModal = false
      let payload = {
        user_id: this.userData.user_id,
        participant_id: inviteAddResp.resp_data.user.user_id,
        session_title: `Session ${this.sessionList.length + 1}`,
        spcm_id: inviteAddResp.resp_data.spcm.spcm_id,
        session_start_ts: slot.slot_start_ts,
        session_end_ts: Number(slot.slot_start_ts) + slot.slot_duration,
        counsellor_id: this.userData.user_id,
        spcm_calendar_id: slot.spcm_calendar_id,
        session_state: null,
        source: "spcm" + this.userData.user_id,
        user_email: inviteAddResp.resp_data.user.user_email
      }

      let sessionAddResponse = await SpcmSessions.spcmSessionAdd(this, payload)

      if (sessionAddResponse) {
        this.spcmList()
      }
    },
    /**
     * goToCalendar
     */
    goToCalendar () {
      this.$router.push(`spcmCalendar_list/${this.userData.user_id}`)
    },
    /**
     * viewDetails
     */
    viewDetails (path) {
      window.open(path, "_blank")
    },
    /**
     * showCreateSession
     */
    showCreateSession () {
      this.showSessionCreationModal = true
    },
    /**
     * closeCreateSession
     */
    closeCreateSession () {
      this.showSessionCreationModal = false
    },
    /**
     * viewSessionDetails
     */
    viewSessionDetails (spcmSessionId) {
      this.$router.push(`/spcm/${spcmSessionId}`)
    },
    /**
     * setUserView
     */
    setUserView () {
      switch (this.userData.user_role) {
        case "USERROLE11111":
          this.columns = [
            { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
            { label: "Student Info", key: "user_name", class: "text-left align-text-top w-300px", sortable: true },
            { label: "Status", key: "spcm_state", class: "text-left align-text-top w-125px", sortable: true },
            { label: "Current Progress", key: "spcm_id", class: "text-left align-text-top w-300px", sortable: true },
            { label: "Updated On", key: "modified_on", class: "text-left align-text-top", sortable: true }
          ]
          break
        case "USERROLE11118":
          this.columns = [
            { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
            { label: "Student Info", key: "user_name", class: "text-left align-text-top text-wrap w-125px", sortable: true },
            { label: "Status", key: "spcm_state", class: "text-left align-text-top w-125px", sortable: true },
            { label: "Current Progress", key: "spcm_id", class: "text-left align-text-top w-300px", sortable: true },
            { label: "Updated On", key: "modified_on", class: "text-left align-text-top", sortable: true }
          ]
      }
    },
    /**
     * Calling Function as per whereFilter
    */
    async getspcmDateWise () {
      this.spcmList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * setFilters
     */
    async setFilters () {
      const userListSearchResp = await User.userListSearch(this, { user_role: "USERROLE11118,USERROLE11111" })
      if (userListSearchResp.resp_status) {
        this.counsellorObjList = userListSearchResp.resp_data.data
      }
    },
    /**
     * spcmStateList
     */
    async spcmStateList () {
      try {
        let spcmStateListResp = await Spcms.spcmStateList(this)
        if (spcmStateListResp.resp_status) {
          let stateObjList = spcmStateListResp.resp_data.data
          for (let stateObj of stateObjList) {
            this.SPCM_STATE_LIST[stateObj.state_id] = stateObj.state
            this.SPCM_STATE_COLORCODE_LIST[stateObj.state_id] = stateObj.state_color_code
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmStateList() and Exception:", err.message)
      }
    },
    /**
     * spcmList
     */
    async spcmList () {
      try {
        if (this.counsellorId) {
          this.whereFilter.counsellor_id = this.counsellorId
        }

        let spcmListResp = await Spcms.spcmList(this, this.whereFilter)
        if (spcmListResp.resp_status) {
          this.spcmObjList = spcmListResp.resp_data.data
          this.totalRows = spcmListResp.resp_data.count
        }
        else {
          this.toastMsg = spcmListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }

        this.updateInfo = this.updateInfo + 1
      }
      catch (err) {
        console.error("Exception occurred at spcmList() and Exception:", err.message)
      }
    },
    /**
     * spcm Status Update
     */
    async spcmStatusUpdate (spcmObj, newState) {
      try {
        this.cvLoadingStatus = true

        let payload = {
          spcm_id: spcmObj.spcm_id,
          spcm_state: newState
        }

        let spcmAddResp = await Spcms.spcmEdit(this, payload)
        await ApiResponse.responseMessageDisplay(this, spcmAddResp)

        if (spcmAddResp && !spcmAddResp.resp_status) {
          return false
        }

        spcmObj.spcm_state = newState
        this.closeSpcmEditModal(spcmObj)
        this.showModelSpcmStatusUpdate = false
      }
      catch (err) {
        console.error("Exception occurred at spcmStatusUpdate() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * spcmOpenEditModal
     */
    spcmOpenEditModal (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/spcm_edit/" + this.spcmEditObj.spcm_id)
        }
        else {
          this.spcmEditObj = item
          this.showModelSpcmEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmOpenEditModal() and Exception:", err.message)
      }
    },
    /**
     * show Spcm Status Update Dialog
     */
    showSpcmStatusUpdateOpenDialog (spcmObj) {
      this.spcmObj = spcmObj
      this.showModelSpcmStatusUpdate = true
    },
    /**
     * showSpcmDeleteDialog
     */
    showSpcmDeleteDialog (spcmObj) {
      try {
        this.spcmObj = spcmObj
        this.showModelSpcmDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSpcmDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * spcmDelete
     */
    async spcmDelete () {
      try {
        let spcmDelResp = await Spcms.spcmDelete(this, this.spcmObj.spcm_id)
        await ApiResponse.responseMessageDisplay(this, spcmDelResp)

        if (spcmDelResp && !spcmDelResp) {
          this.showModelSpcmDelete = false
          return false
        }

        let index = this.spcmObjList.indexOf(this.spcmObj)
        this.spcmObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSpcmDelete = false
      }
      catch (err) {
        console.error("Exception occurred at spcmDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSpcmAddModal
     */
    closeSpcmAddModal (spcmAddData) {
      try {
        if (spcmAddData) {
          if (this.spcmObjList && this.spcmObjList.length >= 1) {
            let spcmObjLength = this.spcmObjList.length
            let lastId = this.spcmObjList[spcmObjLength - 1]["id"]
            spcmAddData.id = lastId + 1
          }
          else {
            this.spcmObjList = []
            spcmAddData.id = 11111
          }

          spcmAddData.created_on = moment()
          this.spcmObjList.unshift(spcmAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelSpcmAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closespcmAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeSpcmEditModal
     */
    closeSpcmEditModal (updatedData) {
      try {
        // Update the List with new Information
        for (let i in this.spcmObjList) {
          if (this.spcmObjList[i].spcm_id == updatedData.spcm_id) {
            this.spcmObjList[i] = updatedData
            break
          }
        }

        this.updateInfo = this.updateInfo + 1
        this.showModelSpcmEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSpcmEditModal() and Exception:", err.message)
      }
    },
    /**
     * openReport
     */
    openReport (url) {
      window.open(url, "_blank")
    },
    /**
     * getNotes
     */
    getNotes (data) {
      return data.latest_counselor_notes ? data.latest_counselor_notes.slice(0, 200) + `<br><br><span class='primary-color pointer'>Read More ...</span>` : ""
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    }
  }
}
</script>
<style lang="css" scoped>
  .button_align{
    display: inline-flex;
    width: auto;
    border: 0px;
  }
</style>
